<template>
  <div>
    <div class="header-title justify-content-between">
      <b>{{ $t("Transaction Gas Fee") }}</b>
    </div>
    <div class="content-wrap">
      <!-- <div class="content-top">
        <div class="duration-wrap">{{ $t("Duration") }}: {{ duration }}</div>
        <div class="export-btn">
          <button>Export</button>
        </div>
      </div> -->
      <div class="content-top">
        <div class="duration-wrap mr-3">
          <div class="mx-2">{{ $t("Duration") }}</div>
          <div class="flex-column date-picker-desktop">
            <date-range-picker
              v-model="DateRange"
              @update="updateRange"
              :opens="'right'"
            >
              <template v-slot:input="picker">
                {{ picker.startDate.toISOString().split("T")[0] }} -
                {{ picker.endDate.toISOString().split("T")[0] }}
              </template>
            </date-range-picker>
            <!-- <input v-model="depositEndDate" :placeholder="$t(`End`)" class="txID-input" type="text"
                onfocus="(this.type='date')" onblur="(this.type='text')" :min="depositStartDate" /> -->
          </div>
          <div class="flex-column date-picker-mobile">
            <date-range-picker
              v-model="DateRange"
              @update="updateRange"
              :opens="'center'"
            >
              <template v-slot:input="picker">
                {{ picker.startDate.toISOString().split("T")[0] }} -
                {{ picker.endDate.toISOString().split("T")[0] }}
              </template>
            </date-range-picker>
          </div>
        </div>
        <div class="duration-wrap">
          <div class="mx-2">{{ $t("Export (chain)") }}</div>
          <div class="d-flex">
            <b-dropdown class="chain-dropdown mr-2" v-model="selectedChain">
              <template #button-content>
                <inline-svg
                    :src="require(`../assets/icon/networks/${selectedChain}.svg`)"
                    width="25"
                    height="25"
                    aria-label="copy"
                  ></inline-svg>
                     {{ selectedChain.toUpperCase() }}
             
              </template>
              <b-dropdown-item
                v-for="fee in Object.keys(feeData)"
                :value="fee"
                :key="fee"
                @click="selectChain(fee)"
              >
                <div class="dropdown-text">
                  <inline-svg
                    :src="require(`../assets/icon/networks/${fee}.svg`)"
                    width="25"
                    height="25"
                    aria-label="copy"
                  ></inline-svg>
                  {{ fee.toUpperCase() }}
                </div>
              </b-dropdown-item>
            </b-dropdown>
            <a class="exp-btn" :href="exportFile" target="_blank">Export</a>
          </div>
        </div>
      </div>

      <div class="loading-wrap" v-if="isLoading">
        <b-spinner variant="info" label="Spinning"></b-spinner>
      </div>

      <div class="fee-container" v-else>
        <div class="fee-wrap" v-if="feeData.trx != ''">
          <div class="fee-header">
            <inline-svg
              :src="require('../assets/icon/networks/trx.svg')"
              width="25"
              height="25"
              aria-label="copy"
            ></inline-svg>
            TRX
          </div>
          <div class="fee-body">
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Gas (Deposit)") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.trx.gas_dep, 5) }}
              </div>
            </div>
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Gas (Withdraw)") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.trx.gas_with, 5) }}
              </div>
            </div>
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Real Gas (Deposit)") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.trx.real_gas_dep, 5) }}
              </div>
            </div>
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Real Gas (Withdraw)") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.trx.real_gas_with, 5) }}
              </div>
            </div>
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Total Real Gas") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.trx.total_real_gas, 5) }}
              </div>
            </div>
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Total Refunable Gas") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.trx.total_refundable_gas, 5) }}
              </div>
            </div>
          </div>
        </div>

        <div class="fee-wrap" v-if="feeData.bsc != ''">
          <div class="fee-header">
            <inline-svg
              :src="require('../assets/icon/networks/bsc.svg')"
              width="25"
              height="25"
              aria-label="copy"
            ></inline-svg>
            BSC
          </div>
          <div class="fee-body">
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Gas (Deposit)") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.bsc.gas_dep, 5) }}
              </div>
            </div>
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Gas (Withdraw)") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.bsc.gas_with, 5) }}
              </div>
            </div>
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Real Gas (Deposit)") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.bsc.real_gas_dep, 5) }}
              </div>
            </div>
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Real Gas (Withdraw)") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.bsc.real_gas_with, 5) }}
              </div>
            </div>
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Total Real Gas") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.bsc.total_real_gas, 5) }}
              </div>
            </div>
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Total Refunable Gas") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.bsc.total_refundable_gas, 5) }}
              </div>
            </div>
          </div>
        </div>

        <div class="fee-wrap" v-if="feeData.eth != ''">
          <div class="fee-header">
            <inline-svg
              :src="require('../assets/icon/networks/eth.svg')"
              width="25"
              height="25"
              aria-label="copy"
            ></inline-svg>
            ETH
          </div>
          <div class="fee-body">
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Gas (Deposit)") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.eth.gas_dep, 5) }}
              </div>
            </div>
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Gas (Withdraw)") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.eth.gas_with, 5) }}
              </div>
            </div>
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Real Gas (Deposit)") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.eth.real_gas_dep, 5) }}
              </div>
            </div>
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Real Gas (Withdraw)") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.eth.real_gas_with, 5) }}
              </div>
            </div>
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Total Real Gas") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.eth.total_real_gas, 5) }}
              </div>
            </div>
            <div class="fee-data-wrap">
              <div class="fee-label">{{ $t("Total Refunable Gas") }} :</div>
              <div class="fee-data">
                {{ formatNum(feeData.eth.total_refundable_gas, 5) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal ref="export-modal" hide-footer :title="`SELECT CHAIN`" centered>
      <div class="d-flex flex-column text-center"></div>
    </b-modal>
  </div>
</template>

<script>
import api from "../helper/api";
import moment from "moment";
import InlineSvg from "vue-inline-svg";
import coinHelper from "../helper/coin.js";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  components: {
    InlineSvg,
    DateRangePicker,
  },
  data() {
    return {
      duration: "Past Month",
      feeData: "",
      DateRange: {
        startDate: "2023-01-01",
        endDate: "2023-12-31",
      },
      isLoading: true,
      baseurl: process.env.VUE_APP_BASE_URL,
      exportFile: "",
      selectedChain: "trx",
    };
  },
  mounted() {
    this.DateRange = {
      startDate: moment().subtract(1, "months").format().split("T")[0],
      endDate: moment().format().split("T")[0],
    };
    this.getTransGasFee();
    // this.exportDownload();
  },
  methods: {
    formatNum(num, decimal) {
      return coinHelper.formatNumberWithCommas(num, decimal);
    },
    dateFormatter(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
    async updateRange() {
      this.DateRange = {
        startDate: this.dateFormatter(this.DateRange.startDate),
        endDate: this.dateFormatter(this.DateRange.endDate),
      };
      console.log(this.DateRange);
      this.getTransGasFee();
    },

    async selectChain(fee) {
      this.selectedChain = fee;
      this.exportDownload();
    },

    async getTransGasFee() {
      this.isLoading = true;

      let param = {
        start: this.DateRange.startDate,
        end: this.DateRange.endDate,
      };

      try {
        const response = await api.apiCall(
          "core",
          "GET",
          "/transaction/gas-fee",
          param
        );
        this.feeData = response.message;
        this.isLoading = false;
        this.exportDownload();
      } catch (error) {
        console.error(error);
      }
    },

    async exportDownload() {
      this.exportFile = `${this.baseurl}${this.selectedChain}/gathers/download?start=${this.DateRange.startDate}&end=${this.DateRange.endDate}`;
    },
  },
};
</script>

<style>
@import "../css/styles.css";

.chain-dropdown button,
.chain-dropdown button:active {
  background: white !important;
  border: 1px solid #ccc !important;
  color: black !important;
}
.chain-dropdown button {
  color: black !important;
}
.content-wrap {
  margin: 4% 6%;
}

.exp-btn {
  height: 35px;
  /* margin: 0px 20px; */
  align-items: center;
  font-weight: bold;
  display: flex;
  color: var(--info);
}

/* .export-btn button {
  border: none;
  font-weight: 600;
  color: white;
  background: var(--primary);
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 26px;
} */

.content-top {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 30px;
  /* gap: 20px; */
  /* flex-wrap: wrap; */
}

.duration-wrap {
  /* background: white;
  border-radius: 30px;
  width: fit-content;
  padding: 6px 12px; */
  font-size: 14px;
  font-weight: 600;
  color: var(--gray);
  width: 340px;
  /* display: flex; */
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.duration-wrap .vue-daterange-picker {
  width: 240px;
}

.duration-wrap .reportrange-text {
  text-align: center;
}
.fee-header {
  background: var(--info);
  color: white;
  border-radius: 20px 20px 0 0;
  padding: 6px 90px 6px 30px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 18px;
  width: fit-content;
}

.fee-container {
  display: flex;
  justify-content: space-between;
  gap: 10%;
}

.fee-wrap {
  width: 100%;
}

.fee-body {
  background: white;
  border-radius: 0 20px 20px 20px;
  padding: 8px 26px 8px 16px;
}

.fee-data-wrap {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.fee-label {
  /* font-weight: 600; */
  /* width: 146px; */
}

.fee-data {
  color: var(--primary);
}

.loading-wrap {
  display: flex;
  justify-content: center;
}

.date-picker-mobile {
  display: none;
}

.date-picker-desktop {
  display: flex;
}

.dropdown-text {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: bold;
}

.dropdown button{
  display: flex;
  align-items: center ;
  gap: 6px
}

@media only screen and (max-width: 768px) {
  .date-picker-desktop {
    display: none;
  }

  .date-picker-mobile {
    display: flex;
  }
  .fee-container {
    flex-direction: column;
  }

  .fee-wrap {
    margin-bottom: 20px;
  }
}
</style>
